/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import LandingNav from 'components/design/LandingNav';

export default function PrivacyScreen() {
    const handleClickScroll = (el) => {
        const element = document.getElementById(el);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="terms">
            <LandingNav onAvail handleClickScroll={handleClickScroll} />
            <div className="terms__hero">Privacy Policy</div>
            <div className="terms__container">
                <div>
                    <h1>
                        <strong>Privacy Policy for Project SINAG WebGIS</strong>
                    </h1>
                    <p>
                        <span>
                            At Project SINAG WebGIS, accessible from
                            https://webgis.sinag.nec.upd.edu.ph, one of our main priorities is the
                            privacy of our visitors. This Privacy Policy document contains types of
                            information that is collected and recorded by Project SINAG WebGIS and
                            how we use it.
                        </span>
                    </p>
                    <p>
                        <span>
                            If you have additional questions or require more information about our
                            Privacy Policy, do not hesitate to contact us.
                        </span>
                    </p>
                    <p>
                        <span>
                            This Privacy Policy applies only to our online activities and is valid
                            for visitors to our website with regards to the information that they
                            shared and/or collect in Project SINAG WebGIS. This policy is not
                            applicable to any information collected offline or via channels other
                            than this website.
                        </span>
                    </p>
                    <h2>
                        <strong>Consent</strong>
                    </h2>
                    <p>
                        <span>
                            By using our website, you hereby consent to our Privacy Policy and agree
                            to its terms.
                        </span>
                    </p>
                    <h2>
                        <strong>Information we collect</strong>
                    </h2>
                    <p>
                        <span>
                            The personal information that you are asked to provide, and the reasons
                            why you are asked to provide it, will be made clear to you at the point
                            we ask you to provide your personal information.
                        </span>
                    </p>
                    <p>
                        <span>
                            If you contact us directly, we may receive additional information about
                            you such as your name, email address, phone number, the contents of the
                            message and/or attachments you may send us, and any other information
                            you may choose to provide.
                        </span>
                    </p>
                    <p>
                        <span>
                            When you register for an Account, we may ask for your contact
                            information, including items such as name, company name, address, email
                            address, and telephone number.
                        </span>
                    </p>
                    <h2>
                        <strong>How we use your information</strong>
                    </h2>
                    <p>
                        <span>
                            We use the information we collect in various ways, including to:
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span>Provide, operate, and maintain our website</span>
                        </li>
                        <li>
                            <span>Improve, personalize, and expand our website</span>
                        </li>
                        <li>
                            <span>Understand and analyze how you use our website</span>
                        </li>
                        <li>
                            <span>Develop new products, services, features, and functionality</span>
                        </li>
                        <li>
                            <span>
                                Communicate with you, either directly or through one of our
                                partners, including for customer service, to provide you with
                                updates and other information relating to the website, and for
                                marketing and promotional purposes
                            </span>
                        </li>
                        <li>
                            <span>Send you emails</span>
                        </li>
                        <li>
                            <span>Find and prevent fraud</span>
                        </li>
                    </ul>
                    <h2>
                        <strong>Log Files</strong>
                    </h2>
                    <p>
                        <span>
                            Project SINAG WebGIS follows a standard procedure of using log files.
                            These files log visitors when they visit websites. All hosting companies
                            do this and a part of hosting services' analytics. The information
                            collected by log files include internet protocol (IP) addresses, browser
                            type, Internet Service Provider (ISP), date and time stamp,
                            referring/exit pages, and possibly the number of clicks. These are not
                            linked to any information that is personally identifiable. The purpose
                            of the information is for analyzing trends, administering the site,
                            tracking users' movement on the website, and gathering demographic
                            information.
                        </span>
                    </p>
                    <h2>
                        <strong>Cookies and Web Beacons</strong>
                    </h2>
                    <p>
                        <span>
                            Like any other website, Project SINAG WebGIS uses 'cookies'. These
                            cookies are used to store information including visitors' preferences,
                            and the pages on the website that the visitor accessed or visited. The
                            information is used to optimize the users' experience by customizing our
                            web page content based on visitors' browser type and/or other
                            information.
                        </span>
                    </p>
                    <h2>
                        <strong>Google DoubleClick DART Cookie</strong>
                    </h2>
                    <p>
                        <span>
                            Google is one of a third-party vendor on our site. It also uses cookies,
                            known as DART cookies, to serve ads to our site visitors based upon
                            their visit to www.website.com and other sites on the internet. However,
                            visitors may choose to decline the use of DART cookies by visiting the
                            Google ad and content network Privacy Policy at the following URL
                            &ndash;{' '}
                        </span>
                        <a href="https://policies.google.com/technologies/ads">
                            <span>https://policies.google.com/technologies/ads</span>
                        </a>
                    </p>
                    <h2>
                        <strong>Our Advertising Partners</strong>
                    </h2>
                    <p>
                        <span>
                            Some of advertisers on our site may use cookies and web beacons. Our
                            advertising partners are listed below. Each of our advertising partners
                            has their own Privacy Policy for their policies on user data. For easier
                            access, we hyperlinked to their Privacy Policies below.
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span>Google </span>
                            <a href="https://policies.google.com/technologies/ads">
                                <span>https://policies.google.com/technologies/ads</span>
                            </a>
                        </li>
                    </ul>
                    <h2>
                        <strong>Advertising Partners Privacy Policies</strong>
                    </h2>
                    <p>
                        <span>
                            You may consult this list to find the Privacy Policy for each of the
                            advertising partners of Project SINAG WebGIS.
                        </span>
                    </p>
                    <p>
                        <span>
                            Third-party ad servers or ad networks uses technologies like cookies,
                            JavaScript, or Web Beacons that are used in their respective
                            advertisements and links that appear on Project SINAG WebGIS, which are
                            sent directly to users' browser. They automatically receive your IP
                            address when this occurs. These technologies are used to measure the
                            effectiveness of their advertising campaigns and/or to personalize the
                            advertising content that you see on websites that you visit.
                        </span>
                    </p>
                    <p>
                        <span>
                            Note that Project SINAG WebGIS has no access to or control over these
                            cookies that are used by third-party advertisers.
                        </span>
                    </p>
                    <h2>
                        <strong>Third Party Privacy Policies</strong>
                    </h2>
                    <p>
                        <span>
                            Project SINAG WebGIS's Privacy Policy does not apply to other
                            advertisers or websites. Thus, we are advising you to consult the
                            respective Privacy Policies of these third-party ad servers for more
                            detailed information. It may include their practices and instructions
                            about how to opt-out of certain options.
                        </span>
                    </p>
                    <p>
                        <span>
                            You can choose to disable cookies through your individual browser
                            options. To know more detailed information about cookie management with
                            specific web browsers, it can be found at the browsers' respective
                            websites.
                        </span>
                    </p>
                    <h2>
                        <strong>CCPA Privacy Rights (Do Not Sell My Personal Information)</strong>
                    </h2>
                    <p>
                        <span>
                            Under the CCPA, among other rights, California consumers have the right
                            to:
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span>
                                Request that a business that collects a consumer's personal data
                                disclose the categories and specific pieces of personal data that a
                                business has collected about consumers.
                            </span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>
                                Request that a business delete any personal data about the consumer
                                that a business has collected.
                            </span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>
                                Request that a business that sells a consumer's personal data, not
                                sell the consumer's personal data.
                            </span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span>
                                If you make a request, we have one month to respond to you. If you
                                would like to exercise any of these rights, please contact us.
                            </span>
                        </li>
                    </ul>
                    <h2>
                        <strong>GDPR Data Protection Rights</strong>
                    </h2>
                    <p>
                        <span>
                            We would like to make sure you are fully aware of all of your data
                            protection rights. Every user is entitled to the following:
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span>
                                The right to access &ndash; You have the right to request copies of
                                your personal data. We may charge you a small fee for this service.
                            </span>
                        </li>
                        <li>
                            <span>
                                The right to rectification &ndash; You have the right to request
                                that we correct any information you believe is inaccurate. You also
                                have the right to request that we complete the information you
                                believe is incomplete.
                            </span>
                        </li>
                        <li>
                            <span>
                                The right to erasure &ndash; You have the right to request that we
                                erase your personal data, under certain conditions.
                            </span>
                        </li>
                        <li>
                            <span>
                                The right to restrict processing &ndash; You have the right to
                                request that we restrict the processing of your personal data, under
                                certain conditions.
                            </span>
                        </li>
                        <li>
                            <span>
                                The right to object to processing &ndash; You have the right to
                                object to our processing of your personal data, under certain
                                conditions.
                            </span>
                        </li>
                        <li>
                            <span>
                                The right to data portability &ndash; You have the right to request
                                that we transfer the data that we have collected to another
                                organization, or directly to you, under certain conditions.
                            </span>
                        </li>
                    </ul>
                    <p>
                        <span>
                            If you make a request, we have one month to respond to you. If you would
                            like to exercise any of these rights, please contact us.
                        </span>
                    </p>
                    <h2>
                        <strong>Children's Information</strong>
                    </h2>
                    <p>
                        <span>
                            Another part of our priority is adding protection for children while
                            using the internet. We encourage parents and guardians to observe,
                            participate in, and/or monitor and guide their online activity.
                        </span>
                    </p>
                    <p>
                        <span>
                            Project SINAG WebGIS does not knowingly collect any Personal
                            Identifiable Information from children under the age of 13. If you think
                            that your child provided this kind of information on our website, we
                            strongly encourage you to contact us immediately and we will do our best
                            efforts to promptly remove such information from our records.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}
