import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ProtectedRoutes from 'components/ProtectedRoute';
import LandingScreen from 'screens/LandingScreen';
import LoginScreen from 'screens/LoginScreen';
import ForgotPasswordScreen from 'screens/ForgotPasswordScreen';
import RegisterScreen from 'screens/RegisterScreen';
import BrowseScreen from 'screens/BrowseScreen';
import ForecastScreen from 'screens/ForecastScreen';
import ProfileScreen from 'screens/ProfileScreen';
import AuthScreen from 'screens/AuthScreen';
import RequestLayerScreen from 'screens/RequestLayerScreen';
import RequestForecastScreen from 'screens/RequestForecastScreen';
import SubscriptionScreen from 'screens/SubscriptionScreen';
import AvailSubsScreen from 'screens/AvailSubsScreen';
import PrivacyScreen from 'screens/PrivacyScreen';
import TermsScreen from 'screens/TermsScreen';
export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/terms-conditions" element={<TermsScreen />} />
                <Route path="/privacy-policy" element={<PrivacyScreen />} />
                <Route path="/avail-subscription" element={<AvailSubsScreen />} />
                <Route path="/browse" element={<BrowseScreen />} />
                <Route path="/forecast" element={<ForecastScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route element={<ProtectedRoutes />}>
                    <Route element={<AuthScreen />}>
                        <Route path="/profile" element={<ProfileScreen />} />
                        <Route path="/requests">
                            <Route path="layer" element={<RequestLayerScreen />} />
                            <Route path="forecast" element={<RequestForecastScreen />} />
                        </Route>
                        <Route path="/subscription" element={<SubscriptionScreen />} />
                    </Route>
                </Route>
                <Route path="/" element={<LandingScreen />} />
            </Routes>
        </BrowserRouter>
    );
}
