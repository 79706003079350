/* eslint-disable react/no-unescaped-entities */

import React, { useEffect } from 'react';
import LandingNav from 'components/design/LandingNav';
import { Button, Text, Box, Image, ActionIcon } from '@mantine/core';
import map from 'assets/icons/map.png';
import climate from 'assets/icons/climate-change.png';
// import api from 'assets/icons/api.png';
import logo from 'assets/logos/sinag.png';
import facebook from 'assets/icons/facebook.png';
import calendar from 'assets/icons/calendar.png';
import yt from 'assets/icons/youtube.png';
import click from 'assets/icons/click.png';
import up from 'assets/logos/up.png';
import nec2 from 'assets/logos/up-nec.png';
import dge from 'assets/logos/dge.png';
import dost from 'assets/logos/dost.png';
import pcieerd from 'assets/logos/pcieerd.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export default function LandingScreen() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const handleClickScroll = (el) => {
        const element = document.getElementById(el);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (state) {
            handleClickScroll(state.redirect);
        }
    }, [state]);

    return (
        <div className="landing">
            <LandingNav handleClickScroll={handleClickScroll} />
            <div className="landing__hero">
                <div className="landing__hero-content">
                    <div className="landing__hero-p">
                        <Text className="text" fw="700" color="orange" fs="italic">
                            WebGIS for Project SINAG
                        </Text>
                        <Text fz="md" sx={{ padding: '20px 0', textAlign: 'justify' }}>
                            {`Web-based geographic information system for Solar PV Resource and Installation Assessment Using Geospatial Technologies project`}{' '}
                        </Text>
                        <Box sx={{ textAlign: 'center', paddingTop: 20 }}>
                            <Button
                                onClick={() => {
                                    navigate('browse');
                                }}
                                color="orange"
                                size="lg">
                                Get Started
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
            <div className="landing__mid" id="services">
                <div className="landing__mid-title">Our Products and Services</div>
                <div className="landing__mid-products">
                    <div className="landing__mid-products--item">
                        <Link to="/browse">
                            <div className="landing__icon">
                                <Image src={map} width={80} />
                            </div>
                        </Link>
                        <div className="landing__text">
                            <Link to="/browse">
                                <Text fz="xl" fw="700" color="orange" fs="italic">
                                    SINAG Map
                                </Text>
                                <Text fz="md" sx={{ padding: '20px 0', textAlign: 'justify' }}>
                                    Map layers and forecasting allows users to make informed
                                    decisions about the viability of installing solar PV systems in
                                    a specific location.
                                </Text>
                            </Link>
                        </div>
                    </div>

                    <div className="landing__mid-products--item">
                        <Link to="/forecast">
                            <div className="landing__icon">
                                <Image src={climate} width={80} />
                            </div>
                        </Link>
                        <div className="landing__text">
                            <Link to="/forecast">
                                <Text fz="xl" fw="700" color="orange" fs="italic">
                                    Data
                                </Text>
                                <Text fz="md" sx={{ padding: '20px 0', textAlign: 'justify' }}>
                                    We offer a wide range of data, including satellite data, weather
                                    data, and other valuable information that can be utilized for
                                    solar PV output forecasting. With these, users can make informed
                                    decisions and stay ahead of the curve in their respective
                                    fields.
                                </Text>
                            </Link>
                        </div>
                    </div>

                    <div className="landing__mid-products--item">
                        <div className="landing__icon">
                            <Image src={calendar} width={80} />
                        </div>
                        <div className="landing__text">
                            <Link to="/avail-subscription">
                                <Text fz="xl" fw="700" color="orange" fs="italic">
                                    Subscriptions
                                </Text>
                                <Text fz="md" sx={{ padding: '20px 0', textAlign: 'justify' }}>
                                    Our subscription service provides users with access to our
                                    premium features and data sets, allowing them to take their
                                    research and analysis to the next level. Subscribe today and
                                    start unlocking the full potential of our data sets.
                                </Text>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="landing__hr" />
            <div className="landing__container" id="contact">
                <div className="landing__footer">
                    <div className="landing__footer-logo">
                        <Image src={logo} width={250} sx={{ margin: '0 auto' }} />
                    </div>
                    <div className="landing__footer-content">
                        <Text fz="xl" fw="700" color="orange" fs="italic">
                            Reach out
                        </Text>
                        <Text sx={{ fontSize: '50px' }} fw="700" color="orange">
                            Project SINAG
                        </Text>
                        <Text fz="md" color="orange">
                            https://www.sinag.nec.upd.edu.ph/
                        </Text>
                        <div className="landing__footer-socials">
                            <ActionIcon
                                onClick={() => {
                                    window.open('https://www.facebook.com/ProjSINAG', '_blank');
                                }}
                                sx={{ marginRight: '20px' }}>
                                <Image src={facebook} width={30} />
                            </ActionIcon>
                            <ActionIcon
                                onClick={() => {
                                    window.open('https://www.youtube.com/@sinag9296', '_blank');
                                }}
                                sx={{ marginRight: '20px' }}>
                                <Image src={yt} width={35} />
                            </ActionIcon>
                            <ActionIcon
                                onClick={() => {
                                    window.open('https://www.sinag.nec.upd.edu.ph/', '_blank');
                                }}>
                                <Image src={click} width={35} />
                            </ActionIcon>
                        </div>
                        <div className="landing__footer-socials btop">
                            <ActionIcon className="landing__logos">
                                <Image src={up} width={35} />
                            </ActionIcon>
                            <ActionIcon className="landing__logos">
                                <Image src={nec2} width={35} />
                            </ActionIcon>
                            <ActionIcon className="landing__logos">
                                <Image src={pcieerd} width={35} />
                            </ActionIcon>
                            <ActionIcon className="landing__logos">
                                <Image src={dost} width={35} />
                            </ActionIcon>
                            <ActionIcon className="landing__logos">
                                <Image src={dge} width={35} />
                            </ActionIcon>
                        </div>
                    </div>
                </div>
                <div className="landing__contact">
                    <div className="addr">
                        <Text fz="xl" fw="700" color="orange" fs="italic">
                            Office Address
                        </Text>
                        <Text fz="md" fw="400" color="black" fs="regular">
                            Rm. 304 Juinio Hall, National Engineering Center, corner Agoncillo St.
                            and Osmeña Avenue, University of the Philippines, Diliman, Quezon City
                        </Text>
                        <Text fz="xl" fw="700" color="orange" fs="italic" mt={20}>
                            Email
                        </Text>
                        <Text fz="md" fw="400" color="black" fs="regular">
                            sinag.nec.upd@up.edu.ph
                        </Text>
                        <Text fz="xl" fw="700" color="orange" fs="italic" mt={20}>
                            Phone
                        </Text>
                        <Text fz="md" fw="400" color="black" fs="regular">
                            Phone 981-5000 (local) 3014
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
}
